import {createSlice} from "@reduxjs/toolkit";

// Redux toolkit cho phép định nghĩa state (VD: "isDarkMode"), định nghĩa các reducers/action (VD: setIsDarkMode)
// ngay trong 1 file JS, cho phép code dễ bảo trì hơn

// định nghĩa giá trị khởi tạo của state
// value=true tức là trang đang render có lỗi
// r/msg: thông tin chi tiết về lỗi
const initialState = {value: false};// isDarkMode = false

// định nghĩa state name, reducers, actions
const slice = createSlice({
    name: "isDarkMode",
    initialState,
    reducers: {
        // action public ra để dùng là: setIsDarkMode(true/false)
        setIsDarkMode: (state, action) => {
            console.log('setIsDarkMode=' + action.payload);
            // nếu state không phải object thì cần return giá trị state mới ở đây
            // nếu state là object thì có thể thay đổi trường của object luôn mà không được return
            // VD: isDarkMode.value = action.payload
            // return action.payload;
            state.value = action.payload;
        }
    }
});

// export state, actions, reducer để các components sử dụng
// lưu ý: quy định đặt tên giống như sample này để dễ hiểu, dễ bảo trì code
export const selectIsDarkMode = (state) => state.isDarkMode;
export const {setIsDarkMode} = slice.actions;
export default slice.reducer;
