import i18n from "i18next";

import commonEn from "./en/common.json";
import commonVi from "./vi/common.json";
import Cookies from "js-cookie";
import {cookiesDomain, cookiesLanguage} from "src/utils/data/env";

export const setLangCookies = (langSelected) => {
    Cookies.set(cookiesLanguage, langSelected, {domain: cookiesDomain});
};

let LANGUAGE = "en";

const langCookies = Cookies.get(cookiesLanguage);
if (langCookies) {
    LANGUAGE = langCookies;
}

i18n.init({
    fallbackLng: ["vi", "en"],
    lng: LANGUAGE,
    interpolation: {escapeValue: false},
    resources: {
        en: {
            translation: commonEn
        },
        vi: {
            translation: commonVi
        }
    }
});

export default i18n;
