/* eslint-disable operator-linebreak */
import {Box, Breadcrumbs as BreadcrumbsMUI} from "@mui/material";
import {Link, useLocation} from "react-router-dom";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {geneUniqueKey} from "src/utils/helpers/textUtils";
import routerNames from "src/utils/data/routerName";
import {useEffect, useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {breadcrumbsStore, setBreadcrumbsInitialState} from "src/store/breadcrumbs";

const routerNamesArr = Object.values(routerNames);

export default function Breadcrumbs({isHomePage}) {
    const dispatch = useDispatch();
    const location = useLocation();
    const {t} = useTranslation();

    const {show: isBreadcrumbs, slugs} = useSelector(breadcrumbsStore);

    useEffect(() => {
        if (location) {
            dispatch(setBreadcrumbsInitialState());
        }
    }, [dispatch, location]);

    const breadcrumbsData = useMemo(() => {
        let curSlug = "";
        let nextSlug = null;
        let routerMatchNextSlug = null;

        const data = location.pathname
            .split("/")
            .filter((r) => r !== "")
            .reduce((acc, breadcrumb, index, breadcrumbsData) => {
                if (nextSlug) {
                    curSlug = nextSlug;
                } else {
                    curSlug += "/" + breadcrumb;
                }
                nextSlug = curSlug + "/" + breadcrumbsData[index + 1];

                const routerMatch = routerMatchNextSlug
                    ? routerMatchNextSlug
                    : routerNamesArr.find((r) => r.slug === curSlug);
                if (routerMatch) {
                    routerMatchNextSlug = routerNamesArr.find((r) => r.slug === nextSlug);

                    let breadcrumbName = routerMatch.name;
                    if (slugs && slugs[routerMatch.slug]) {
                        breadcrumbName = slugs[routerMatch.slug];
                    }

                    acc.push(
                        routerMatchNextSlug ? (
                            <Link key={geneUniqueKey()} to={routerMatch.slug}>
                                {t(breadcrumbName)}
                            </Link>
                        ) : (
                            <p key={geneUniqueKey()}>{t(breadcrumbName)}</p>
                        )
                    );
                }

                return acc;
            }, []);

        return data;
    }, [slugs, location.pathname, t]);

    return (
        <Box marginBottom="15px" padding="10px 10px 0 10px" display={isBreadcrumbs ? "block" : "none"}>
            <BreadcrumbsMUI aria-label="breadcrumb" separator={<ChevronRightIcon />}>
                {isHomePage &&
                    (breadcrumbsData.length === 0 ? (
                        <p>{routerNames.HOME.name}</p>
                    ) : (
                        <Link key={geneUniqueKey()} to={routerNames.HOME.slug}>
                            {routerNames.HOME.name}
                        </Link>
                    ))}
                {breadcrumbsData}
            </BreadcrumbsMUI>
        </Box>
    );
}
