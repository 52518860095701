import config from "src/config";

/**
 * Ưu tiên dùng cách export này
 * - Với cách export từng key như phái dưới sẽ thuận tiện hơn cho việc sử dụng sau này
 *      + Khi gọi biến lên sẽ nhanh hơn và giảm thiểu sai sót khi import
 *      + Khi cần thiết có thể tìm đến các vị trí đang sử dụng thuận tiện hơn thay vì phải dùng search không chính xác
 */
export const {
    apiUrl,
    authToken,

    cookiesDomain,
    cookiesLanguage,
    timeSaveToken,
    saveInfo,

    role,
    roleStringee,

    roleView,
    roleAdd,
    roleEdit,
    roleDelete,
    roleExportExcel,
    roleApprove,
    roleUploadFile,
    debugging,

    listYearOfExperience,
    listFromOfWork,
    listSalary,
    listTypeArticle,

    recruitmentPostPageDefault,
    cultureCompanyPageDefault,

    listDomain,
    domainDefault
} = config;

// Đây là export default để có thể gọi biến config ở bất cứ đâu trong Project
export default config;
