import axios from "axios";
import ApiError from "./ApiError";
import {postMethod} from "src/utils/helpers/textUtils";
import {apiUrl} from "src/utils/data/env";

export default class ApiBase {
    constructor() {
        this.baseUrl = apiUrl || "";
    }

    httpPost(uri, data, options) {
        return this.httpRequest(uri, "POST", data, options);
    }

    httpPut(uri, data, options) {
        return this.httpRequest(uri, "PUT", data, options);
    }

    httpGet(uri, data, options) {
        return this.httpRequest(uri, "GET", data, options);
    }

    httpDelete(uri, options) {
        return this.httpRequest(uri, "DELETE", undefined, options);
    }

    httpRequest(uri, method, data, options) {
        return new Promise((resolve, reject) => {
            axios({
                method: method,
                url: this.baseUrl + uri,
                data: method !== "GET" ? data : postMethod(data),
                params: method === "GET" ? data || "" : "",
                withCredentials: true,
                ...options
            })
                .then((res) => {
                    // HTTP status=2xx
                    // console.log("http res", res);

                    if (!res.data) {
                        reject(new ApiError(-6, "SERVER_RESPONSE_EMPTY", ""));
                        return;
                    }

                    if (typeof res.data !== "object") {
                        reject(new ApiError(-8, "SERVER_NOT_RESPONSE_JSON", "JSON from server: " + res.data));
                        return;
                    }

                    if (res.data) {
                        resolve(res.data);
                        return;
                    }

                    reject(new ApiError(res.r, "SERVER_RESPONSE_ERROR", res.msg + ", ErrorCode=" + res.data.r));
                })
                .catch((axiosError) => {
                    // console.log("http error", axiosError);

                    if (!axiosError.response) {
                        // server khong tra response: network error
                        reject(new ApiError(-2, "NETWORK_ERROR", axiosError.message));
                        return;
                    }

                    if (!axiosError.response.data) {
                        // server tra response.data rong: data from server is empty
                        reject(new ApiError(-3, "SERVER_RESPONSE_EMPTY", axiosError.message));
                        return;
                    }

                    if (typeof axiosError.response.data === "object") {
                        // server tra response.data la Object
                        reject(
                            new ApiError(
                                axiosError.response.data.r,
                                "SERVER_RESPONSE_ERROR",
                                axiosError.response.data.msg
                            )
                        );
                        return;
                    }

                    // server tra response.data la String
                    reject(new ApiError(-5, "SERVER_NOT_RESPONSE_JSON", axiosError.response.data));
                });
        });
    }
}
