import {Collapse, List, ListItem, ListItemButton, ListItemText} from "@mui/material";
import clsx from "clsx";
import {useEffect, useState} from "react";
import {Link, useLocation} from "react-router-dom";
import routerNames from "src/utils/data/routerName";
import {KeyboardArrowDown} from "@mui/icons-material";
import {useTranslation} from "react-i18next";

// const useStyles = makeStyles((theme) => ({
//     root: {
//       width: '100%',
//       maxWidth: 360,
//       backgroundColor: theme.palette.background.paper,
//     },
//     nested: {
//       paddingLeft: theme.spacing(4),
//     },
//   }));

export default function LeftToolbar() {
    const {t} = useTranslation();
    const location = useLocation();
    const {pathname} = location;

    const [key, setKey] = useState("/");
    const [open, setOpen] = useState(true);

    const handleClick = () => {
        setOpen(!open);
    };

    const checkClassNameHref = (keyPath) => {
        return clsx("w-full px-[32px] py-[16px] block mb-0 menu-item-child-only", {
            active: keyPath === key
        });
    };

    const checkAriaCurrentHref = (keyPath) => {
        return keyPath === key ? "page" : undefined;
    };

    useEffect(() => {
        setKey(pathname);
    }, [pathname]);

    return (
        <div className="left-toolbar">
            <List component="nav" className="py-0">
                <List component="ul" disablePadding>
                    <ListItem disablePadding>
                        <ListItemButton onClick={handleClick} className="px-[10px] py-[16px] menu-item-submenu">
                            <ListItemText primary="Blogs" className="a"/>
                            <KeyboardArrowDown className={clsx("transition-all", {"rotate-180": open})}/>
                        </ListItemButton>
                    </ListItem>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <List component="ul" disablePadding>
                            <ListItem disablePadding>
                                <Link
                                    to={routerNames.ARTICLES.slug}
                                    className={checkClassNameHref(routerNames.ARTICLES.slug)}
                                    aria-current={checkAriaCurrentHref(routerNames.ARTICLES.slug)}>
                                    Articles
                                </Link>
                            </ListItem>
                            <ListItem disablePadding>
                                <Link
                                    to={routerNames.PAGES.slug}
                                    className={checkClassNameHref(routerNames.PAGES.slug)}
                                    aria-current={checkAriaCurrentHref(routerNames.PAGES.slug)}>
                                    {t("page.pages")}
                                </Link>
                            </ListItem>
                        </List>
                    </Collapse>
                </List>
            </List>
        </div>
    );
}
