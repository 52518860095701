import {configureStore} from "@reduxjs/toolkit";
import isDarkModeReducer from "./isDarkMode";
import leftToolbarReducer from "./leftToolbar";
import breadcrumbsReducer from "./breadcrumbs";
import languageReducer from "./language";
import humanResourcesArticlesReducer from "./humanResources/articles";
import articlesReducer from "./articles";

export const store = configureStore({
    reducer: {
        isDarkMode: isDarkModeReducer,
        leftToolbar: leftToolbarReducer,
        breadcrumbs: breadcrumbsReducer,
        language: languageReducer,
        humanResourcesArticles: humanResourcesArticlesReducer,
        articles: articlesReducer
    }
});
