import {ToastContainer as ToastContainerLib} from "react-toastify";
import {ReactComponent as IconSuccess} from "src/assets/images/icons/circle-success.svg";
import {ReactComponent as IconWarning} from "src/assets/images/icons/circle-warning.svg";
import {ReactComponent as IconError} from "src/assets/images/icons/circle-error.svg";

export default function ToastContainer({isTest = false}) {
    return (
        <ToastContainerLib
            className="p-0"
            toastStyle={{padding: "19px 20px 16px 18px"}}
            bodyClassName="p-0"
            position="bottom-left"
            icon={(props) => {
                if (isTest) {
                    return "";
                }

                let icon = <IconWarning />;
                const type = props.type || "warning";
                if (type === "success") {
                    icon = <IconSuccess />;
                } else if (type === "warning") {
                    icon = <IconWarning />;
                } else if (type === "error") {
                    icon = <IconError />;
                }

                return icon;
            }}
        />
    );
}
