import omitBy from "lodash/omitBy";
import isNil from "lodash/isNil";
import ShortUniqueId from "short-unique-id";
import unidecode from "unidecode";
import parse from "html-react-parser";
import ReactDOMServer from "react-dom/server";

export const postMethod = (input = {}) => {
    return omitBy(input, isNil);
};

export const geneUniqueKey = () => {
    const uid = new ShortUniqueId();
    return uid();
};

export const parserHtmlToPlainText = (htmlString) => {
    let plainText = "";

    if (htmlString) {
        const parseOptions = {
            replace: (domNode) => {
                if (domNode.type === "text") {
                    plainText += domNode.data;
                }
            }
        };

        parse(htmlString, parseOptions);
    }

    return plainText;
};

export const renderHeadingTree = (htmlString) => {
    const getAllDataNode = (node) => {
        const getText = (r) => {
            let text = "";
            if (r.type === "text") {
                text += r.data;
            }

            if (r.children) {
                text += getAllDataNode(r.children);
            }

            return text;
        };

        let text = "";

        if (Array.isArray(node)) {
            node.forEach((r) => {
                text += getText(r);
            });
        } else {
            text += getText(node);
        }

        return text;
    };

    let parsedHtml = htmlString;
    const parsedTree = [];
    let prevHeadingLevel = 0;

    if (htmlString) {
        const parseOptions = {
            replace: (domNode) => {
                if (domNode.type === "tag" && /^h[1-6]$/.test(domNode.name)) {
                    const headingLevel = parseInt(domNode.name[1]);
                    // Extract heading text from all child nodes
                    const headingText = getAllDataNode(domNode.children);
                    const headingTextId = unidecode(headingText)
                        .replace(/[^\w\s-]/g, "")
                        .replace(/\s+/g, "-");

                    let currentLevel = parsedTree;
                    for (let i = 1; i < headingLevel; i++) {
                        const item = currentLevel[currentLevel.length - 1];
                        if (
                            item &&
                            item.level < headingLevel &&
                            (prevHeadingLevel !== headingLevel || prevHeadingLevel === headingLevel)
                        ) {
                            currentLevel = currentLevel[currentLevel.length - 1].children;
                        }
                    }
                    prevHeadingLevel = headingLevel;

                    currentLevel.push({
                        level: headingLevel,
                        text: headingText,
                        id: headingTextId,
                        children: []
                    });

                    const id = domNode.attribs && domNode.attribs.id;
                    if (!id) {
                        // Generate a unique ID based on heading text
                        domNode.attribs = {
                            ...domNode.attribs,
                            id: headingTextId
                        };
                    }
                }
            }
        };

        const parsed = parse(htmlString, parseOptions);
        parsedHtml = ReactDOMServer.renderToString(parsed);
    }

    return {
        headingTree: parsedTree,
        parsedHtml: parsedHtml
    };
};

export const boldSearchText = (q, originalString) => {
    if (!originalString) {
        return <></>;
    }

    const plainText = parserHtmlToPlainText(originalString);

    if (!q) {
        return <p className="text-ellipsis line-clamp-1">{plainText}</p>;
    }

    const indexOfQ = unidecode(plainText.toLowerCase().replace(/…/g, "#")).indexOf(unidecode(q.toLowerCase()));
    if (indexOfQ < 0) {
        return <p className="text-ellipsis line-clamp-1">{plainText}</p>;
    }

    const qStr = plainText.substring(indexOfQ, indexOfQ + q.length);
    const startStrTmp = plainText.substring(0, indexOfQ);
    const endStrTmp = plainText.substring(indexOfQ + q.length);

    // TODO Lấy ra đoạn text bắt đầu trong phần tìm kiếm
    const indexOfFirstBreakLine = startStrTmp.lastIndexOf("\n");
    const indexOfFirstEndLine = startStrTmp.lastIndexOf(". ");
    let indexOfStart = undefined;
    if (indexOfFirstBreakLine > 0 || indexOfFirstEndLine > 0) {
        if (indexOfFirstBreakLine > indexOfFirstEndLine) {
            indexOfStart = indexOfFirstBreakLine + 1;
        } else {
            indexOfStart = indexOfFirstEndLine + 2;
        }
    }
    const startStr = startStrTmp.substring(indexOfStart, indexOfQ);

    // TODO Lấy ra đoạn text kết thúc trong phần tìm kiếm
    const indexOfLastBreakLine = endStrTmp.indexOf("\n");
    const indexOfLastEndLine = endStrTmp.indexOf(". ");
    let indexOfEnd = undefined;
    if (indexOfLastBreakLine > 0 || indexOfLastEndLine > 0) {
        if (indexOfLastBreakLine > 0 && indexOfLastBreakLine < indexOfLastEndLine) {
            indexOfEnd = indexOfLastBreakLine + 1;
        } else {
            indexOfEnd = indexOfLastEndLine + 2;
        }
    }
    const endStr = endStrTmp.substring(0, indexOfEnd > 0 ? indexOfEnd : undefined);

    let textBold = <></>;
    const isExistsStartStr = ![undefined, null, ""].includes(startStr);
    const isExistsEndStr = ![undefined, null, ""].includes(endStr);
    if (isExistsStartStr && isExistsEndStr) {
        textBold = (
            <div className="flex flex-grow">
                <p className="text-ellipsis line-clamp-1 min-w-fit whitespace-break-spaces">
                    {startStr.length > 50 ? startStr.substring(0, 50) + "... " : startStr}
                </p>
                <p className="text-ellipsis line-clamp-1">
                    <strong>{qStr}</strong>
                    {endStr}
                </p>
            </div>
        );
    } else if (isExistsStartStr) {
        textBold = (
            <p className="text-ellipsis line-clamp-1">
                {startStr}
                <strong>{qStr}</strong>
            </p>
        );
    } else if (isExistsEndStr) {
        textBold = (
            <p className="text-ellipsis line-clamp-1">
                <strong>{qStr}</strong>
                {endStr}
            </p>
        );
    } else {
        textBold = (
            <p className="text-ellipsis line-clamp-1">
                <strong>{qStr}</strong>
            </p>
        );
    }

    return textBold;
};

export const parseSlug = (slug) => {
    return unidecode(slug)
        .replace(/[^\-a-zA-Z0-9\s]/g, "")
        .replace(/\s+/g, "-")
        .toLowerCase();
};
