const configDynamic = await import(`./config.${process.env.REACT_APP_ENV}.mjs`);

const config = {
    apiUrl: "",
    authToken: process.env.REACT_APP_AUTH_TOKEN,

    cookiesLanguage: "user_language",
    timeSaveToken: 30,
    saveInfo: "info",

    role: "role",
    roleStringee: "snk_page",

    roleView: 5,
    roleAdd: 4,
    roleEdit: 3,
    roleDelete: 2,
    roleExportExcel: 1,
    roleApprove: 0,
    roleUploadFile: "role_upload_file",
    debugging: "debug",

    listYearOfExperience: {
        "no_experience_yet": {key: 1, label: "No experience yet"},
        "under_1_year": {key: 2, label: "Under 1 year"},
        "1_year_2_years": {key: 3, label: "1 year - 2 years"},
        "2_years_3_years": {key: 4, label: "2 years - 3 years"},
        "3_years_5_years": {key: 5, label: "3 years - 5 years"},
        "over_5_years": {key: 6, label: "Over 5 years"}
    },
    listFromOfWork: {
        full_time: {key: 1, label: "Full time"},
        part_time: {key: 2, label: "Part-time"},
        flexible: {key: 3, label: "Flexible"}
    },
    listSalary: {
        negotiable: {key: 1, label: "Negotiable"},
        salary_range: {key: 2, label: "Salary range"}
    },
    listTypeArticle: {
        blog: {key: 1, label: "Blog"},
        recruitment_post: {key: 2, label: "Recruitment post"},
        culture_company: {key: 3, label: "Culture post"}
    },

    recruitmentPostPageDefault: "Hiring Page",
    cultureCompanyPageDefault: "Văn hóa doanh nghiệp (Chi tiết)",

    ...configDynamic.default
    // ...require(`./config.${process.env.REACT_APP_ENV}.mjs`).default
};

export default config;
