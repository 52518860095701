import Cookies from "js-cookie";
import {role, roleStringee} from "../data/env";
import {toast} from "react-toastify";
import CustomToast from "src/common_components/toast";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {Trans} from "react-i18next";

export const getCapabilities = (roleType) => {
    return JSON.parse(Cookies.get(role) || "{}")?.[roleType] || [];
};

export const checkRolePermission = ({roles, rules = "||"}) => {
    let isAccess = false;

    const capabilities = getCapabilities(roleStringee);
    if (Array.isArray(roles)) {
        if (["||", "&&"].includes(rules)) {
            for (let i = 0; i < roles.length; i++) {
                isAccess = Boolean(capabilities[roles[i]]);
                if (isAccess) {
                    if (rules === "||") {
                        break;
                    } else if (rules === "&&") {
                        continue;
                    }
                }
            }
        }
    } else {
        isAccess = Boolean(capabilities[roles]);
    }

    if (!isAccess) {
        toast.error(
            <CustomToast
                title={<Trans i18nKey="permission.permission"></Trans>}
                content={<Trans i18nKey="permission.not_access"></Trans>}
            />
        );
    }

    return isAccess;
};

export const CheckRolePermission = ({roles, rules = "||", children}) => {
    const navigate = useNavigate();
    const {t} = useTranslation();

    const [render, setRender] = useState(<></>);

    useEffect(() => {
        if (!checkRolePermission({roles: roles, rules: rules, t: t})) {
            navigate(-1);
        } else {
            setRender(children);
        }
    }, [children, navigate, roles, rules, t]);

    return render;
};
