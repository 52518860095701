import {Outlet} from "react-router-dom";
import {Box, CssBaseline, ThemeProvider} from "@mui/material";

import LeftToolbar from "./LeftToolbar";
import TopToolbar from "./TopToolbar";

// css & theme
import themeBuilder from "./theme";
import "react-toastify/dist/ReactToastify.css";
import "src/styles/main_layout/index.scss";
import "src/styles/font_icon.scss";
import "src/styles/common.scss";

import {useSelector} from "react-redux";
import {selectIsDarkMode} from "src/store/isDarkMode";
import Breadcrumbs from "../breadcrumbs/Breadcrumbs";
import ToastContainer from "../toast/ToastContainer";

export default function MainLayout({isBreadcrumbs = true, isHomePage = false}) {
    const isDarkMode = useSelector(selectIsDarkMode);

    return (
        <ThemeProvider theme={themeBuilder(isDarkMode.value)}>
            <CssBaseline />

            <div
                className="main-layout-container"
                id="mainLayoutContainer"
                data-theme={isDarkMode.value ? "dark" : "light"}>
                <TopToolbar />
                <div className="main-page">
                    <LeftToolbar />
                    <Box className="main-page-content text-gray-3">
                        {isBreadcrumbs && <Breadcrumbs isHomePage={isHomePage} />}
                        <Outlet />
                    </Box>
                    <ToastContainer />
                </div>
            </div>
        </ThemeProvider>
    );
}
