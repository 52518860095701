import {createSlice} from "@reduxjs/toolkit";

const initialState = {data: [], idLangSelected: undefined};

const slice = createSlice({
    name: "language",
    initialState,
    reducers: {
        setLanguageData: (state, action) => {
            state.data = action.payload;
        },
        setLanguageIdSelected: (state, action) => {
            state.idLangSelected = action.payload;
        }
    }
});

export const languageStore = (state) => state.language;
export const {setLanguageData, setLanguageIdSelected} = slice.actions;
export default slice.reducer;
