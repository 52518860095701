import Cookies from "js-cookie";
import React, {useCallback, useEffect} from "react";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import {apiPermissionUltil} from "src/api/ApiPermissionUltil";
import CustomToast from "src/common_components/toast";
import {authToken, cookiesDomain, role, roleStringee, roleView} from "src/utils/data/env";
import routerNames from "src/utils/data/routerName";
import {getCapabilities} from "src/utils/helpers/permissionUltils";

const AuthContext = React.createContext();

export default function AuthProvider({children}) {
    const {t} = useTranslation();

    function logout() {
        if (window.location.pathname !== routerNames.LOGIN.slug) {
            Cookies.remove(authToken, {domain: cookiesDomain});
            window.location.pathname = routerNames.LOGIN.slug;
        }
    }

    const fecthPermission = useCallback(() => {
        apiPermissionUltil
            .permissionUltil()
            .then((res) => {
                const data = res.data || {};
                for (const [key, value] of Object.entries(data)) {
                    data[key] = value.map((r) => Number(r));
                }
                Cookies.set(role, JSON.stringify(res.data), {domain: cookiesDomain});

                const capabilities = getCapabilities(roleStringee);
                if (!capabilities[roleView]) {
                    toast.error(
                        <CustomToast title={t("permission.permission")} content={t("permission.not_access")} />
                    );
                    logout();
                }
            })
            .catch((err) => {
                logout();
            });
    }, [t]);

    if (!Cookies.get(authToken)) {
        logout();
    } else if (window.location.pathname === routerNames.LOGIN.slug) {
        window.location.pathname = routerNames.HOME.slug;
    }

    useEffect(() => {
        if (Cookies.get(authToken)) {
            fecthPermission();
        }
    }, [fecthPermission]);

    const value = {logout, fecthPermission};

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuthContext() {
    return React.useContext(AuthContext);
}
