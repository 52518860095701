import {ClickAwayListener, Grow, ListItemText, MenuItem, Paper, Popper, Tooltip} from "@mui/material";
import {useEffect, useState} from "react";
import Avatar from "src/assets/images/logo/avatar.webp";

/** @jsxImportSource @emotion/react */
import {useAuthContext} from "src/provider/auth/authProvider";
import {getSaveInfo} from "src/utils/helpers/localStorage";

export default function MenuProfileButton() {
    const [name, setName] = useState(false);
    const [email, setEmail] = useState(false);
    const {logout} = useAuthContext();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        logout();
    };

    useEffect(() => {
        const info = getSaveInfo();
        setName(info.name);
        setEmail(info.email);
    }, []);

    return (
        <div className="btn-1">
            <Tooltip
                placement="bottom"
                enterDelay={0}
                leaveDelay={0}
                disableHoverListener={open}
                arrow
                title="User Profile">
                <button onClick={handleClick} className="navbar-login-user">
                    <div className="avatar1">
                        <img alt="Travis Howard" src={Avatar} />
                    </div>
                    <div className="info-text" title="huy@stringee.com">
                        <div className="info-name float-left">{name}</div>
                        <div className="info-mail">{email}</div>
                    </div>
                </button>
            </Tooltip>
            {/* List menu khi click vào */}
            <Popper
                className="w-56"
                open={open}
                anchorEl={anchorEl}
                role={undefined}
                placement="bottom-start"
                transition
                disablePortal>
                {({TransitionProps, placement}) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: placement === "bottom-start" ? "left top" : "left bottom"
                        }}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuItem onClick={() => handleLogout()}>
                                    <ListItemText>Logout</ListItemText>
                                </MenuItem>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
            {/* List menu khi click vào */}
        </div>
    );
}
