import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    page: {isLoading: false, listData: []},
    tagLabel: {isLoading: false, listData: []},
    tag: {isLoading: false, listData: []}
};

const slice = createSlice({
    name: "articles",
    initialState,
    reducers: {
        setArticlesInitialState: (state, action) => {
            state.page = initialState.page;
        },
        setArticlesPage: (state, action) => {
            state.page = {...initialState.page, ...action.payload};
        },
        setArticlesTagLabel: (state, action) => {
            state.tagLabel = {...initialState.tagLabel, ...action.payload};
        },
        setArticlesTag: (state, action) => {
            state.tag = {...initialState.tag, ...action.payload};
        }
    }
});

export const articlesStore = (state) => state.articles;
export const {setArticlesInitialState, setArticlesPage, setArticlesTagLabel, setArticlesTag} = slice.actions;
export default slice.reducer;
