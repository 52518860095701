import {createSlice} from "@reduxjs/toolkit";

const initialState = {slugs: undefined, show: true};

const slice = createSlice({
    name: "breadcrumbs",
    initialState,
    reducers: {
        setBreadcrumbsInitialState: (state, action) => {
            state.slugs = initialState.slugs;
            state.show = initialState.show;
        },
        setBreadcrumbsSlugCustom: (state, action) => {
            state.slugs = action.payload;
        },
        setBreadcrumbsIsShow: (state, action) => {
            state.show = action.payload;
        }
    }
});

export const breadcrumbsStore = (state) => state.breadcrumbs;
export const {setBreadcrumbsInitialState, setBreadcrumbsSlugCustom, setBreadcrumbsIsShow} = slice.actions;
export default slice.reducer;
