import {createSlice} from "@reduxjs/toolkit";

export const listMenu = {
    blog: "Blog",
    humanResources: "Human Resources"
};

const initialState = {open: listMenu.blog, key: "/"};

const slice = createSlice({
    name: "leftToolbar",
    initialState,
    reducers: {
        setLeftToolbarInitialState: (state, action) => {
            state.open = initialState.open;
            state.key = initialState.key;
        },
        setLeftToolbarOpen: (state, action) => {
            state.open = action.payload;
        },
        setLeftToolbarKey: (state, action) => {
            state.key = action.payload;
        }
    }
});

export const leftToolbarStore = (state) => state.leftToolbar;
export const {setLeftToolbarInitialState, setLeftToolbarOpen, setLeftToolbarKey} = slice.actions;
export default slice.reducer;
