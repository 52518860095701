import {Link} from "react-router-dom";

export default function CustomToast({children, title, content, linkToDetail, onDetail, linkToDissmiss, onDissmiss}) {
    const role = "toast-notification";
    return (
        <>
            {children || (
                <div role={role} aria-label={title}>
                    <div>
                        <p className="text-base text-[var(--text-color-toast-title)]">{title}</p>
                        {/* <Tooltip arrow title={content}>
                            <p
                                className="text-xs text-[var(--text-color-toast-content)]
                                text-ellipsis line-clamp-3">
                                {content}
                            </p>
                        </Tooltip> */}
                        <p className="text-xs text-[var(--text-color-toast-content)]">{content}</p>
                    </div>
                    <div className="flex gap-3 text-xs mt-4">
                        {linkToDetail && (
                            <Link to={linkToDetail} className="a-2">
                                View Detais
                            </Link>
                        )}
                        {onDetail && <p onClick={onDetail}>View Detais</p>}
                        {linkToDissmiss && (
                            <Link to={linkToDissmiss} className="text">
                                Dismiss
                            </Link>
                        )}
                        {onDissmiss && <p onClick={onDissmiss}>Dismiss</p>}
                    </div>
                </div>
            )}
        </>
    );
}
