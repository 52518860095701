const routerNames = {
    HOME: {
        slug: "/",
        name: "Blogs"
    },

    DASHBOARD: {
        slug: "/dashboard",
        name: "Dashboard"
    },

    ERROR: {
        slug: "/error",
        name: "Error"
    },

    LOGIN: {
        slug: "/login",
        name: "Login"
    },

    ARTICLES: {
        slug: "/articles",
        name: "Articles"
    },

    CREATE_ARTICLE: {
        slug: "/articles/create",
        name: "Create an article"
    },

    UPDATE_ARTICLE: {
        slug: "/articles/update",
        name: "Edit an article"
    },

    PAGES: {
        slug: "/pages",
        name: "Pages"
    },

    CREATE_PAGE: {
        slug: "/pages/create",
        name: "Create Pages"
    },

    UPDATE_PAGE: {
        slug: "/pages/update",
        name: "Edit Pages"
    },

    HUMAN_RESOURCES: {
        slug: "/human-resources",
        name: "Human Resources"
    },

    HUMAN_RESOURCES_ARTICLES: {
        slug: "/human-resources/articles",
        name: "Articles"
    },

    HUMAN_RESOURCES_CREATE_ARTICLE: {
        slug: "/human-resources/articles/create",
        name: "Create an article"
    },

    HUMAN_RESOURCES_UPDATE_ARTICLE: {
        slug: "/human-resources/articles/update",
        name: "Edit an article"
    },

    HUMAN_RESOURCES_PAGES: {
        slug: "/human-resources/pages",
        name: "Pages"
    },

    HUMAN_RESOURCES_CREATE_PAGE: {
        slug: "/human-resources/pages/create",
        name: "Create Pages"
    },

    HUMAN_RESOURCES_UPDATE_PAGE: {
        slug: "/human-resources/pages/update",
        name: "Edit Pages"
    },

    BLOGS: {
        slug: "/blogs",
        name: "Blog"
    },
    FORM: {
        slug: "/form",
        name: "Form"
    },
    TEXT: {
        slug: "/text",
        name: "Text"
    },
    CATEGORIES: {
        slug: "/categories",
        name: "Categories"
    },
    CREATE_CATEGORY: {
        slug: "/categories/create-category",
        name: "Create an category"
    },
    UPDATE_CATEGORY: {
        slug: "/categories/update-category",
        name: "Edit an category"
    }
};

export default routerNames;
