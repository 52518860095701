import {lazy} from "react";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import ErrorPage from "./pages/ErrorPage";
// import Home from "./pages/Home";
import MainLayout from "./common_components/main_layout/MainLayout";
import {I18nextProvider} from "react-i18next";
import i18n from "./languages/i18n";
import routerNames from "./utils/data/routerName";
import LazyLoad from "src/common_components/lazy_load";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import {CheckRolePermission} from "./utils/helpers/permissionUltils";
import {listDomain, roleAdd, roleEdit} from "./utils/data/env";

const Blog = lazy(() => import("./pages/Blog"));
const Form = lazy(() => import("./pages/Form"));
const Text = lazy(() => import("./pages/Text"));

const AuthProvider = lazy(() => import("./provider/auth/authProvider"));

const CreateOrUpdatePagesProvider = lazy(() => import("./provider/pages/CreateOrUpdate"));
const PagesProvider = lazy(() => import("./provider/pages"));

const CreateOrUpdateArticlesProvider = lazy(() => import("./provider/articles/CreateOrUpdate"));
const ArticlesProvider = lazy(() => import("./provider/articles"));

const HumanResoucesArticlesProvider = lazy(() => import("./provider/human_resources/articles"));
const HumanResoucesCreateOrUpdatePagesProvider = lazy(() =>
    import("./provider/human_resources/articles/CreateOrUpdate")
);

const Category = lazy(() => import("./pages/category"));
const CreateOrUpdateCategory = lazy(() => import("./pages/category/CreateOrUpdate"));
const Login = lazy(() => import("./pages/Login"));
const Dashboard = lazy(() => import("./pages/dashboard"));

const Article = lazy(() => import("./pages/articles"));
const CreateOrUpdateArticle = lazy(() => import("./pages/articles/CreateOrUpdate"));

const HumanResoucesArticle = lazy(() => import("./pages/human_resources/articles"));
const HumanResoucesCreateOrUpdateArticle = lazy(() => import("./pages/human_resources/articles/CreateOrUpdate"));

const Pages = lazy(() => import("./pages/pages"));
const CreateOrUpdatePage = lazy(() => import("./pages/pages/CreateOrUpdate"));

export const router = createBrowserRouter([
    {
        path: routerNames.DASHBOARD.slug,
        element: <MainLayout />,
        errorElement: <ErrorPage />,
        children: [
            {
                index: true,
                element: (
                    <LazyLoad>
                        <Dashboard />
                    </LazyLoad>
                )
            }
        ]
    },
    {
        path: routerNames.HOME.slug,
        element: <MainLayout isHomePage={true} />,
        errorElement: <ErrorPage />,
        children: [
            {
                index: true,
                element: (
                    <LazyLoad>
                        <Dashboard />
                    </LazyLoad>
                )
            },
            {
                path: routerNames.BLOGS.slug,
                element: (
                    <LazyLoad>
                        <Blog />
                    </LazyLoad>
                )
            },
            {
                path: routerNames.FORM.slug,
                element: (
                    <LazyLoad>
                        <Form />
                    </LazyLoad>
                )
            },
            {
                path: routerNames.TEXT.slug,
                element: (
                    <LazyLoad>
                        <Text />
                    </LazyLoad>
                )
            },
            {
                path: routerNames.ARTICLES.slug,
                children: [
                    {
                        index: true,
                        element: (
                            <LazyLoad>
                                <ArticlesProvider>
                                    <Article />
                                </ArticlesProvider>
                            </LazyLoad>
                        )
                    },
                    {
                        path: routerNames.CREATE_ARTICLE.slug + "/:type",
                        element: (
                            <LazyLoad>
                                <CheckRolePermission roles={roleAdd}>
                                    <CreateOrUpdateArticlesProvider>
                                        <CreateOrUpdateArticle />
                                    </CreateOrUpdateArticlesProvider>
                                </CheckRolePermission>
                            </LazyLoad>
                        )
                    },
                    {
                        path: routerNames.UPDATE_ARTICLE.slug + "/:id",
                        element: (
                            <LazyLoad>
                                <CheckRolePermission roles={roleEdit}>
                                    <CreateOrUpdateArticlesProvider>
                                        <CreateOrUpdateArticle />
                                    </CreateOrUpdateArticlesProvider>
                                </CheckRolePermission>
                            </LazyLoad>
                        )
                    }
                ]
            },
            {
                path: routerNames.PAGES.slug,
                children: [
                    {
                        index: true,
                        element: (
                            <LazyLoad>
                                <PagesProvider>
                                    <Pages
                                        listDomain={Object.values(listDomain).filter(
                                            (r) => r !== listDomain["career.stringee.com"]
                                        )}
                                    />
                                </PagesProvider>
                            </LazyLoad>
                        )
                    },
                    {
                        path: routerNames.CREATE_PAGE.slug,
                        element: (
                            <LazyLoad>
                                <CreateOrUpdatePagesProvider>
                                    <CreateOrUpdatePage />
                                </CreateOrUpdatePagesProvider>
                            </LazyLoad>
                        )
                    },
                    {
                        path: routerNames.UPDATE_PAGE.slug + "/:id",
                        element: (
                            <LazyLoad>
                                <CreateOrUpdatePagesProvider>
                                    <CreateOrUpdatePage />
                                </CreateOrUpdatePagesProvider>
                            </LazyLoad>
                        )
                    }
                ]
            },
            {
                path: routerNames.CATEGORIES.slug,
                children: [
                    {
                        index: true,
                        element: (
                            <LazyLoad>
                                <Category />
                            </LazyLoad>
                        )
                    },
                    {
                        path: routerNames.CREATE_CATEGORY.slug,
                        element: (
                            <LazyLoad>
                                <CreateOrUpdateCategory />
                            </LazyLoad>
                        )
                    },
                    {
                        path: routerNames.UPDATE_CATEGORY.slug+ "/:id",
                        element: (
                            <LazyLoad>
                                <CreateOrUpdateCategory />
                            </LazyLoad>
                        )
                    }
                ]
            }
        ]
    },
    {
        path: routerNames.HUMAN_RESOURCES.slug,
        element: <MainLayout />,
        errorElement: <ErrorPage />,
        children: [
            // {
            //     index: true,
            //     element: <Home />
            // },
            {
                path: routerNames.HUMAN_RESOURCES_ARTICLES.slug,
                children: [
                    {
                        index: true,
                        element: (
                            <LazyLoad>
                                <HumanResoucesArticlesProvider>
                                    <HumanResoucesArticle />
                                </HumanResoucesArticlesProvider>
                            </LazyLoad>
                        )
                    },
                    {
                        path: routerNames.HUMAN_RESOURCES_CREATE_ARTICLE.slug + "/:type",
                        element: (
                            <LazyLoad>
                                <CheckRolePermission roles={roleEdit}>
                                    <HumanResoucesCreateOrUpdatePagesProvider>
                                        <HumanResoucesCreateOrUpdateArticle />
                                    </HumanResoucesCreateOrUpdatePagesProvider>
                                </CheckRolePermission>
                            </LazyLoad>
                        )
                    },
                    {
                        path: routerNames.HUMAN_RESOURCES_UPDATE_ARTICLE.slug + "/:id",
                        element: (
                            <LazyLoad>
                                <CheckRolePermission roles={roleEdit}>
                                    <HumanResoucesCreateOrUpdatePagesProvider>
                                        <HumanResoucesCreateOrUpdateArticle />
                                    </HumanResoucesCreateOrUpdatePagesProvider>
                                </CheckRolePermission>
                            </LazyLoad>
                        )
                    }
                ]
            },
            {
                path: routerNames.HUMAN_RESOURCES_PAGES.slug,
                children: [
                    {
                        index: true,
                        element: (
                            <LazyLoad>
                                <PagesProvider>
                                    <Pages listDomain={[listDomain["career.stringee.com"]]} />
                                </PagesProvider>
                            </LazyLoad>
                        )
                    },
                    {
                        path: routerNames.HUMAN_RESOURCES_CREATE_PAGE.slug,
                        element: (
                            <LazyLoad>
                                <CreateOrUpdatePagesProvider>
                                    <CreateOrUpdatePage />
                                </CreateOrUpdatePagesProvider>
                            </LazyLoad>
                        )
                    },
                    {
                        path: routerNames.HUMAN_RESOURCES_UPDATE_PAGE.slug + "/:id",
                        element: (
                            <LazyLoad>
                                <CreateOrUpdatePagesProvider>
                                    <CreateOrUpdatePage />
                                </CreateOrUpdatePagesProvider>
                            </LazyLoad>
                        )
                    }
                ]
            }
        ]
    },
    {
        path: routerNames.LOGIN.slug,
        element: (
            <LazyLoad>
                <Login />
            </LazyLoad>
        )
    },
    {
        path: routerNames.ERROR.slug,
        element: <ErrorPage />
    }
]);

function App() {
    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <I18nextProvider i18n={i18n}>
                <AuthProvider>
                    <RouterProvider router={router} />
                </AuthProvider>
            </I18nextProvider>
        </LocalizationProvider>
    );
}

export default App;
