import ApiBase from "./ApiBase";

class ApiLanguage extends ApiBase {
    constructor() {
        super();
        this.path = "/v1/language";
    }

    list(data) {
        return this.httpGet(this.path, data);
    }
}

export const apiLanguage = new ApiLanguage();
