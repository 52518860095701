import {
    ClickAwayListener,
    Grow,
    ListItemIcon,
    ListItemText,
    MenuItem,
    MenuList,
    Paper,
    Popper,
    Tooltip
} from "@mui/material";
import {useState} from "react";
import {useDispatch} from "react-redux";
import {setIsDarkMode} from "src/store/isDarkMode";

export default function MenuThemeButton() {
    // xử lý tắt/bật menu
    // 'Menu' sử dụng 'Popover' sẽ block thanh cuộn trang
    // 'MenuList' không block thanh cuộn
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const dispatch = useDispatch();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleSelectThemeClick = (isDark) => {
        handleClose();
        console.log("handleSelectThemeClick======isDark=" + isDark);
        dispatch(setIsDarkMode(isDark));
    };

    return (
        <div className="btn-1">
            <Tooltip
                placement="bottom"
                enterDelay={0}
                leaveDelay={0}
                disableHoverListener={open}
                arrow
                title="Change theme">
                <button
                    className={open ? "btn btn-icon active" : "btn btn-icon"}
                    id="basic-button"
                    aria-controls={open ? "composition-menu" : undefined}
                    aria-haspopup="true"
                    // aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}>
                    <i className="ki-outline ki-night-day fs-1"></i>
                </button>
            </Tooltip>
            {/* List menu khi click vào */}
            <Popper open={open} anchorEl={anchorEl} role={undefined} placement="bottom-start" transition disablePortal>
                {({TransitionProps, placement}) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: placement === "bottom-start" ? "left top" : "left bottom"
                        }}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList
                                    autoFocusItem={open}
                                    id="composition-menu"
                                    aria-labelledby="composition-button"
                                    // onKeyDown={handleListKeyDown}
                                >
                                    {/* Menu co icon*/}
                                    <MenuItem onClick={() => handleSelectThemeClick(false)}>
                                        <ListItemIcon>
                                            {/* <NightlightOutlinedIcon fontSize="small"/>*/}
                                            <i className="ki-outline ki-night-day fs-1"></i>
                                        </ListItemIcon>
                                        <ListItemText>Light</ListItemText>
                                    </MenuItem>

                                    {/* Menu co icon*/}
                                    <MenuItem onClick={() => handleSelectThemeClick(true)}>
                                        <ListItemIcon>
                                            {/* <LightModeOutlinedIcon fontSize="small"/>*/}
                                            <i className="ki-outline ki-moon theme-dark-show fs-1"></i>
                                        </ListItemIcon>
                                        <ListItemText>Dark</ListItemText>
                                    </MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
            {/* List menu khi click vào */}
        </div>
    );
}
