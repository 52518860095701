import {Box} from "@mui/material";
import MenuLanguageButton from "./top_toolbar/MenuLanguageButton";
import MenuThemeButton from "./top_toolbar/MenuThemeButton";
import MenuProfileButton from "./top_toolbar/MenuProfileButton";
import logo from "src/assets/images/logo/logo.png";

export default function TopToolbar() {
    return (
        <div className="top-toolbar">
            <div className="top-toolbar-left">
                <Box component="img" alt="SNK Logo" src={logo} maxWidth={191}/>
            </div>
            <div className="top-toolbar-right">
                <div className="btn-1">
                    <div className="btn btn-icon">
                        <i className="ki-outline ki-magnifier fs-1"></i>
                    </div>
                </div>
                <div className="btn-1">
                    <div className="btn btn-icon">
                        <i className="ki-outline ki-tablet-ok fs-1"></i>
                    </div>
                </div>
                <div className="btn-1">
                    <div className="btn btn-icon">
                        <i className="ki-outline ki-notification-bing fs-1"></i>
                    </div>
                </div>

                {/* menu theme: code menu này quá dài, do cần xử lý tắt bật menu => tách ra component riêng */}
                <MenuThemeButton/>
                <MenuLanguageButton/>
                <MenuProfileButton/>

            </div>
        </div>
    );
}
