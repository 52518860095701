import {KeyboardArrowDown} from "@mui/icons-material";
import {red} from "@mui/material/colors";
import {createTheme} from "@mui/material/styles";

function themeBuilder(isDarkMode) {
    if (!isDarkMode) {
        // light
        return createTheme({
            components: {
                MuiButton: {
                    styleOverrides: {
                        root: {
                            textTransform: "none",
                            fontSize: "1.14286rem",
                            padding: "10px 22.5px",
                            borderRadius: "6px"
                        }
                    },
                    variants: [
                        {
                            props: {color: "primary", variant: "contained"},
                            style: {
                                "color": "var(--button-primary-color)",
                                "backgroundColor": "var(--button-primary-bg-color)",
                                ":hover": {
                                    "backgroundColor": "var(--button-primary-hover-bg-color)"
                                }
                            }
                        },
                        {
                            props: {color: "secondary", variant: "contained"},
                            style: {
                                "color": "var(--button-secondary-color)",
                                "backgroundColor": "var(--button-secondary-bg-color)",
                                ":hover": {
                                    "backgroundColor": "var(--button-secondary-hover-bg-color)"
                                }
                            }
                        }
                    ]
                },
                MuiSelect: {
                    defaultProps: {
                        IconComponent: KeyboardArrowDown
                    },
                    styleOverrides: {
                        select: {
                            padding: "12px 42px 12px 16px"
                        },
                        icon: {
                            transitionProperty: "all",
                            transitionTimingFunction: "cubic-bezier(0.4, 0, 0.2, 1)",
                            transitionDuration: "150ms"
                        }
                    }
                },
                MuiAutocomplete: {
                    defaultProps: {
                        popupIcon: <KeyboardArrowDown />
                    },
                    styleOverrides: {
                        inputRoot: {
                            padding: "12px 42px 12px 16px"
                        },
                        popupIndicator: {
                            transitionProperty: "all",
                            transitionTimingFunction: "cubic-bezier(0.4, 0, 0.2, 1)",
                            transitionDuration: "150ms"
                        }
                    }
                },
                MuiFormControlLabel: {
                    styleOverrides: {
                        root: {
                            "&.checked .MuiFormControlLabel-label": {
                                color: "var(--c-blue-3)"
                            },
                            ".MuiButtonBase-root.Mui-disabled": {
                                color: "var(--c-gray-4)"
                            },
                            ".MuiFormControlLabel-label.Mui-disabled": {
                                color: "var(--c-dark)"
                            }
                        }
                    }
                },
                MuiTab: {
                    styleOverrides: {
                        root: {
                            "color": "#0000004D",
                            "textTransform": "unset",
                            "&.Mui-selected": {
                                color: "#000",
                                background: "transparent"
                            }
                        }
                    }
                },
                MuiFormHelperText: {
                    styleOverrides: {
                        root: {
                            marginTop: 0
                        }
                    },
                    defaultProps: {
                        className: "text-red-600 text-xs absolute -bottom-5"
                    }
                },
                // MuiModal: {
                //     styleOverrides: {
                //         root: {
                //             "zIndex": 1,
                //             ".modal-box": {
                //                 "position": "absolute",
                //                 "top": "50%",
                //                 "left": "50%",
                //                 "transform": "translate(-50%, -50%)",
                //                 "backgroundColor": common.white,
                //                 "width": "var(--modal-box-width)",
                //                 "maxWidth": "100vw",
                //                 "padding": "30px 50px",
                //                 "maxHeight": "100vh",
                //                 "overflow": "auto",
                //                 "&:focus-visible": {
                //                     outline: "none"
                //                 },
                //                 ".modal-footer": {
                //                     backgroundColor: "var(--c-gray-1)"
                //                 }
                //             }
                //         }
                //     }
                // },
                MuiAccordionSummary: {
                    styleOverrides: {
                        content: {
                            "margin": 0,
                            "&.Mui-expanded": {
                                "margin": 0
                            }
                        }
                    }
                }
            },
            typography: {
                fontFamily: ['"Inter"', '"Helvetica"', '"sans-serif"'].join(","),
                fontSize: 14,
                h1: {
                    fontWeight: "500"
                },
                h2: {
                    fontWeight: "500"
                },
                h3: {
                    fontWeight: "500"
                },
                h4: {
                    fontWeight: "500"
                },
                h5: {
                    fontWeight: "500"
                },
                h6: {
                    fontWeight: "500"
                }
            },
            palette: {
                mode: "light",
                primary: {
                    main: "#009ef7",
                    // dark: 'rgba(0,110,172,0)',
                    contrastText: "rgba(255,255,255,0.87)"
                },
                secondary: {
                    main: "#efefef",
                    dark: "#dcdada",
                    contrastText: "#7E8299"
                },
                error: {
                    main: red.A400
                }
            },
            text: {
                primary: "rgba(36,183,0,0.87)",
                secondary: "rgba(88,88,88,0.6)",
                disabled: "rgba(119,119,119,0.38)",
                hint: "#b0a0ff"
            }
        });
    }

    // dark
    return createTheme({
        typography: {
            fontFamily: ['"Inter"', '"Helvetica"', '"sans-serif"'].join(","),
            fontSize: 14,
            h1: {
                fontWeight: "500"
            },
            h2: {
                fontWeight: "500"
            },
            h3: {
                fontWeight: "500"
            },
            h4: {
                fontWeight: "500"
            },
            h5: {
                fontWeight: "500"
            },
            h6: {
                fontWeight: "500"
            }
        },
        palette: {
            mode: "dark",
            primary: {
                main: "#009ef7",
                // dark: 'rgba(0,110,172,0)',
                contrastText: "rgba(255,255,255,0.87)"
            },
            secondary: {
                main: "#efefef",
                dark: "#dcdada",
                contrastText: "#7E8299"
            },
            error: {
                main: red.A400
            },
            background: {
                primary: "#1e1e2d",
                secondary: "#1e1e2d"
                // paper: deepOrange[900]
            }
        },
        text: {
            primary: "rgba(36,183,0,0.87)",
            secondary: "rgba(88,88,88,0.6)",
            disabled: "rgba(119,119,119,0.38)",
            hint: "#b0a0ff"
        }
    });
}

export default themeBuilder;
