import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    page: {isLoading: false, listData: []},
    tagLabel: {isLoading: false, listData: []},
    tag: {isLoading: false, listData: []}
};

const slice = createSlice({
    name: "humanResourcesArticles",
    initialState,
    reducers: {
        setHumanResourcesArticlesInitialState: (state, action) => {
            state.page = initialState.page;
        },
        setHumanResourcesArticlesPage: (state, action) => {
            state.page = {...initialState.page, ...action.payload};
        },
        setHumanResourcesArticlesTagLabel: (state, action) => {
            state.tagLabel = {...initialState.tagLabel, ...action.payload};
        },
        setHumanResourcesArticlesTag: (state, action) => {
            state.tag = {...initialState.tag, ...action.payload};
        }
    }
});

export const humanResourcesArticlesStore = (state) => state.humanResourcesArticles;
export const {
    setHumanResourcesArticlesInitialState,
    setHumanResourcesArticlesPage,
    setHumanResourcesArticlesTagLabel,
    setHumanResourcesArticlesTag
} = slice.actions;
export default slice.reducer;
